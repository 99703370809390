<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style lang="css">
.el-container {
  height: calc(100vh - 1px);
}
.el-textarea__inner {
  font-family: Arial, Helvetica, sans-serif !important;
}

.el-date-editor .el-range-separator{
  padding: 0px;
}
::-webkit-scrollbar {
  width: 10px; /* 控制滚动条宽度 */
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* 控制滚动条背景色 */
}

::-webkit-scrollbar-thumb {
  background-color: #aaa; /* 控制滑块（滚动按钮）的颜色 */
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px; /* 控制滑块的圆角大小 */
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); /* 控制滑块的阴影效果 */
}
::-webkit-scrollbar-thumb:hover {
  background-color: #999; /* 当鼠标悬停在滑块上时的颜色 */
}
</style>