import Mock from 'mockjs'

export default {
	getMenu: config => {
		const { username, password } = JSON.parse(config.body)
		// 先判断用户是否存在
		// 判断账号和密码是否对应
		// if (username === 'admin' && password === 'admin') {
			return {
				code: 20000,
				data: {
					menu: [
						{
							path: '/',
							name: 'Index',
							label: '首页配置',
							icon: 's-home',
							url: 'Index/index'
						},
					],
					token: Mock.Random.guid(),
					message: '获取成功'
				}
			}
	}
}