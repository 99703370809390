// 获取最近某天日期--n可为正负数或0
const getRecentDay = (n) => {
  let dd = new Date();
  dd.setDate(dd.getDate() + (n || 0)); //获取n天后的日期
  let y = dd.getFullYear();
  let m = dd.getMonth() + 1; //获取当前月份的日期
  let d = dd.getDate();
  let day = y + "/" + (m < 10 ? "0" + m : m) + "/" + (d < 10 ? "0" + d : d);
  return day;
}

const dealdate = (date, tag) => {
  const dt = new Date(date);
  const y = dt.getFullYear();
  const m = (dt.getMonth() + 1 + "").padStart(2, "0");
  const d = (dt.getDate() + "").padStart(2, "0");
  return `${y}${tag ? tag : '-'}${m}${tag ? tag : '-'}${d}`;
}

const editGear = (date) => {
  if (date == '2,2,2,2' || date == '2') {
    return '浓郁'
  } else if (date == '0,0,0,0' || date == '1') {
    return '醇香'
  } else if (date == '-2,-2,-2,-2' || date == '0') {
    return '绵香'
  } else {
    return date || 'N/A'
  }
}

// 导出所有方法
export default {
  getRecentDay,
  dealdate,
  editGear
}