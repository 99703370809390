import Vue from 'vue'
import App from './App.vue'
import './assets/css/index.css' //全局公共样式
import router from './router' //路由
import Router from 'vue-router'
import store from './store'; //Vuex
import axios from 'axios';
import utils from './assets/js/utils.js'
import Cookie from 'js-cookie'
import videojs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$videojs = videojs
import './api/mock';
// import {Button,Container,Aside,Header,Main,Menu,Submenu,MenuItemGroup,MenuItem,Dropdown,DropdownItem} from 'element-ui'
import ElementUI, {
  MessageBox
} from 'element-ui';

import websocket from 'vue-native-websocket';
Vue.use(websocket, '', {
    connectManually: true, // 手动连接
    format: 'json', // json格式
    reconnection: true, // 是否自动重连
    reconnectionAttempts: 5, // 自动重连次数
    reconnectionDelay: 2000, // 重连间隔时间
});

// 全局修改默认配置，点击空白处不能关闭弹窗
ElementUI.Dialog.props.closeOnClickModal.default = false
// 全局修改默认配置，按下ESC不能关闭弹窗
ElementUI.Dialog.props.closeOnPressEscape.default = false
import {
  mapState,
  mapMutations,
} from "vuex";
import {
  setMenu,
  addMenu,
  clearMene
} from '@/store/tab.js'
import 'element-ui/lib/theme-chalk/index.css';
import './directives'
Vue.prototype.utils = utils
Vue.use(ElementUI)
Vue.use(Router)

Vue.config.productionTip = false
Vue.prototype.$confirm = MessageBox.confirm
// Vue.prototype.$message = Message


function getList() {
  let routerList = JSON.parse(localStorage.getItem('routerList')) || []

  routerList = routerList.map((item, index) => {
    if (item.children && item.children.length > 0) {
      item.children = item.children.map((it, ind) => {
        if (it.path != '//') {
          it.component = () => import('@/views' + it.path + '/index')
        }
        return it;
      });
    }
    if (item.path != '//') {
      item.component = () => import('@/views' + item.path + '/index')
    }
    return item;
  });
  let arr = [{
    path: "/",
    name: "Main",
    component: () => import("@/views/Main.vue"),
    redirect: "/login",
    children: routerList,
  }, ];
  // 替换现有router的routes
  router.matcher = new Router({
    mode: 'history', //浏览器工作模式
    routes: [{
        path: "/login",
        name: "login",
        component: () => import("@/views/Login/Login.vue"),
      },{
        path: "/H5GotoApp",
        name: "H5GotoApp",
        component: () => import("@/views/H5GotoApp/H5GotoApp.vue"),
      },
      {
        path: "/DoctorAI",
        name: "DoctorAI",
        component: () => import("@/views/DoctorAI/DoctorAI.vue"),
      },
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/redirect/index.vue"),
      },
      {
        path: '*',
        name: "404",
        component: () => import('@/views/redirect/404.vue')
      },
    ],
  }).matcher;
  router.addRoutes(arr);
}
getList()
function formateObjToParamStr(paramObj) {
  const sdata = [];
  for (let attr in paramObj) {
    sdata.push(`${attr}=${paramObj[attr]}`);
  }
  return sdata.join('&');
};

// 导航守卫
router.beforeEach((to, from, next) => {
  store.commit('getToken') //防止Vuex丢失Token信息
  let token = store.state.user.token
  let routerList = JSON.parse(localStorage.getItem('routerList'))
  if (!from.name && token) {
    if (routerList) {
      // if (!to.name || to.name == '404') {
        // setTimeout(() => {
          // getList()
          // next()
        // }, 100)
      // }
    } else {
      store.commit('clearToken')
    }
  }
  // console.log('获取到的所有路由', router.getRoutes())
  token = store.state.user.token
  if (to.name === 'H5GotoApp' || to.name === 'DoctorAI') {
    next()
  } else if (!token && to.name !== 'login') {
    next({
      name: 'login'
    })
  } else if (token && to.name === 'login') {
    localStorage.setItem('page', 'Index')
    next({
      name: 'Index'
    })
  } else {
    localStorage.setItem('page', to.name)
    if (to.name !== 'login') {
      let tabs = JSON.parse(localStorage.getItem('tabs'))
      // tab查找不到就去添加
      if (tabs && !tabs.find(item => item.name === to.name)) {
        let it = JSON.parse(localStorage.getItem('routerList'))
        it = it.find(item => item.name === to.name)
        if (it) {
          tabs.push({
            ...it,
            path: to.path +'?' + formateObjToParamStr(to.query)
          })
          store.commit('initTabs', tabs)
        }
      }
    }
    next()
  }
})


new Vue({
  router,
  store,
  render: h => h(App),
  // 全局事件总线
  beforeCreate() {
    // 将axios放到Vue的原型上，使全局可以使用
    Vue.prototype.axios = axios
  },
  created() {
    store.commit('addMenu', router)
  }

}).$mount('#app')